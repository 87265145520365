<template>
    <div id="UserLocation">
        <p>{{componentText.location}}</p>
        <b-form-select v-model="selectedLocation" class="select-location">
            <option v-for="location in locations.locations" :value="location.location_id" :class="{selected: locations.current_loc == location.location_id}">{{location.location_label}}</option>
        </b-form-select>
        <b-button  @click="setLocation" class="cta-button px-5 py-3 my-4">{{componentText.save}}</b-button>
        <Popup v-if="showLocationPopup" :content="locationPopupContent" v-on:close="hideLocationPopup"></Popup>
    </div>

</template>

<script>

import axios from "axios";
import config from "@/config/constants";
import Popup from "@/components/Popup.vue"

export default {
    name: "UserLocation",
    components: {
        Popup
    },
    data() {
        return {
            loading: true,
            user: this.$store.getters.user,
            selectedLocation: '',
            locations: {},
            locationPopupContent: {
                header: '',
                message: ''
            },
            showLocationPopup: false,
        };
    },
    mounted() {
        this.getLocations()
    },
    methods: {
        async getLocations() {
            const payload = {
                user_id: this.$store.getters.user_id
            }
            let res = await axios.post(config.api_env + '/application/api-hc/get-locations', payload, config.options)
            if (res.data.success) {
                this.locations = res.data
            } else if (res.data.err && res.data.err.includes('3994')) {
                this.$store.dispatch('logout')
                this.$router.push("/login");
            }
            this.selectedLocation = this.locations.current_loc
        },
        async setLocation() {
            const payload = {
                user_id: this.$store.getters.user_id,
                location_id: this.selectedLocation
            }
            let res = await axios.post(config.api_env + '/application/api-hc/set-location', payload, config.options)
            if (res.data.success) {
                this.locationPopupContent.header = this.componentText.locationSuccess
                this.locationPopupContent.message = this.componentText.locationSuccessMessage
                this.showLocationPopup = true
            }
        },
        hideLocationPopup(){
            this.showLocationPopup = false
        }
    },
    computed: {
        componentText() {
            return this.getText.loggedIn.settings;
        },
    },
    watch: {},
};
</script>

<style lang="less">
#UserLocation{
    min-height: 350px;

}
</style>
